import React, { PropsWithChildren } from "react";

interface HeroProps {
  bgSrc: string;
}

export default (props: PropsWithChildren<HeroProps>) => {
  const { bgSrc } = props;

  return (
    <div className="hero" style={{ backgroundImage: `url(${bgSrc})` }}>
      {props.children}
    </div>
  );
};
