import React, { FC } from "react";
import { HeadFC } from "gatsby";
import Layout from "../layout";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "components/SEO";
import Hero from "components/Hero";
import Button from "components/Button";
import { BasePageContext, TemplatePageProps } from "utils/types";
import PortfolioGrid from "components/PortfolioGrid";

const heroHomeImg = require("images/hero-home.jpg").default;
const htHomeImg = require("images/hillary-taylor-home.jpg").default;
const meetHillary = require("../images/meet-hillary.svg").default;

export default (props: TemplatePageProps<BasePageContext>) => {
  const { appData } = props.pageContext;

  const sortedData = appData.portfolioEntries.slice().sort( (prev, value) => { return prev.order - value.order } )

  return (
    <Layout bodyClass="home" appData={appData}>
      <Hero bgSrc={heroHomeImg} />
      <PortfolioGrid portfolioEntries={sortedData.slice(0,6)} isSample />
      <div className="bg-offwhite">
        <div className="container--medium spaced-100">
          <div className="meet-hillary">
            <div className="content">
            <img className="header-svg" src={meetHillary} alt="Meet Hillary"
            style={{width: 140, marginBottom: 20}} />
              <p>
                Raised on the peninsula south of San Francisco, Hillary learned
                the art of designing the home environment from her mother.
                Hillary graduated cum laude from Princeton University, worked
                for an investment banking firm and received a J.D. from J.
                Reuben Clark Law School before founding her business in 2003.
              </p>
              <Button link="/about" classes="blue no-margin">
                Read More
              </Button>
            </div>
            <div className="image">
              <img src={htHomeImg} alt="Hillary Taylor" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const Head: HeadFC = () => (
  <SEO
    title="Home"
    ogMeta={{
      title: "Hillary Taylor Interiors",
    }}
  ></SEO>
);